import { createSlice } from '@reduxjs/toolkit';

// project imports
import axiosServices from 'utils/axios';
import { dispatch } from 'store';
import { apiUrl, domain } from 'utils/config';

// types
import { InitialStateProps } from '../../types/cms';

// initial state
const initialState: InitialStateProps = {
    error: null,
    isLoading: false,
    landingPage: {
        section_intro_landings: {
            title: '',
            description: '',
            button: {
                name: '',
                link: '',
                isNavigate: false
            },
            image: {
                data: {
                    attributes: {
                        url: ''
                    }
                }
            }
        },
        section_deploy_guides: {
            title: '',
            description: '',
            button: {
                name: '',
                link: '',
                isNavigate: false
            },
            right_images: {
                title: '',
                description: '',
                image: {
                    data: {
                        attributes: {
                            url: ''
                        }
                    }
                },
                button: {
                    name: '',
                    link: '',
                    isNavigate: false
                }
            },
            left_images: {
                title: '',
                description: '',
                image: {
                    data: {
                        attributes: {
                            url: ''
                        }
                    }
                },
                button: {
                    name: '',
                    link: '',
                    isNavigate: false
                }
            }
        },
        section_innovative_pricings: {
            title: '',
            description: '',
            button: {
                name: '',
                link: '',
                isNavigate: false
            },
            gpu_card_img: {
                data: {
                    attributes: {
                        url: ''
                    }
                }
            },
            costs_img: {
                data: {
                    attributes: {
                        url: ''
                    }
                }
            },
            gpu_solutions: {
                title: '',
                description: '',
                solutions: []
            }
        },
        kaisar_worker_monitors: {
            title: '',
            description: '',
            image: {
                data: {
                    attributes: {
                        url: ''
                    }
                }
            },
            monitor: {
                title: '',
                description: '',
                metrics: {
                    value: 0,
                    unit: '',
                    caption: ''
                }
            },
            performance: {
                title: '',
                description: '',
                metrics: {
                    value: 0,
                    unit: '',
                    caption: ''
                }
            },
            reward: {
                title: '',
                description: '',
                metrics: {
                    value: 0,
                    unit: '',
                    caption: ''
                }
            },
            track_services: []
        },
        kaisar_signup_nows: {
            title: '',
            description: '',
            image: {
                data: {
                    attributes: {
                        url: ''
                    }
                }
            },
            button: {
                name: '',
                link: '',
                isNavigate: false
            }
        },
        kaisar_earn_workers: {
            title: '',
            description: '',
            gpu_card_img: {
                data: {
                    attributes: {
                        url: ''
                    }
                }
            },
            earning_img: {
                data: {
                    attributes: {
                        url: ''
                    }
                }
            },
            maximize_profits: {
                title: '',
                description: '',
                solutions: []
            }
        },
        kaisar_explores: {
            title: '',
            description: '',
            button: {
                name: '',
                link: '',
                isNavigate: false
            },
            gpu_locations: {
                image: {
                    data: {
                        attributes: {
                            url: ''
                        }
                    }
                },
                location: []
            },
            cluster_img: {
                title: '',
                description: '',
                image: {
                    data: {
                        attributes: {
                            url: ''
                        }
                    }
                }
            },
            score: []
        }
    },
    aboutUsPage: {
        header: {
            title: '',
            description: '',
            image: {
                data: {
                    attributes: {
                        url: ''
                    }
                }
            }
        },
        features: {
            title: '',
            description: '',
            features: []
        },
        mission: {
            title: '',
            description: '',
            image: {
                data: {
                    attributes: {
                        url: ''
                    }
                }
            }
        },
        partners: {
            title: '',
            image: []
        },
        satisfactions_section: {
            title: '',
            description: '',
            satisfactions: []
        },
        empower_map: {
            title: '',
            description: '',
            map: []
        }
    },
    service: {
        data: []
    },
    faqs: {
        data: []
    }
};

const slice = createSlice({
    name: 'cms',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        isLoading(state, action) {
            state.isLoading = action.payload;
        },
        getLandingPageSuccess(state, action) {
            state.landingPage = action.payload;
        },
        getAboutUsPageSuccess(state, action) {
            state.aboutUsPage = action.payload;
        },
        getServiceSuccess(state, action) {
            state.service = action.payload;
        },
        getFAQsSuccess(state, action) {
            state.faqs = action.payload;
        }
    }
});

export default slice.reducer;

export function getLandingPage() {
    return async () => {
        try {
            const response = await axiosServices.get(
                `${apiUrl}/api/kaisar-landings?filters[domain_service][type][$eq]=${domain}&populate=section_intro_landings.image&populate=section_intro_landings.button&populate=section_deploy_guides.button&populate=section_deploy_guides.right_images.image&populate=section_deploy_guides.right_images.button&populate=section_deploy_guides.left_images.image&populate=section_deploy_guides.left_images.button&populate=section_innovative_pricings.gpu_card_img&populate=section_innovative_pricings.costs_img&populate=section_innovative_pricings.gpu_solutions.solutions&populate=kaisar_worker_monitors.image&populate=kaisar_worker_monitors.monitor.metrics&populate=kaisar_worker_monitors.performance.metrics&populate=kaisar_worker_monitors.track_services.image&populate=kaisar_worker_monitors.reward.metrics&populate=kaisar_signup_nows.images&populate=kaisar_signup_nows.button&populate=kaisar_earn_workers.maximize_profits.solutions&populate=kaisar_earn_workers.gpu_card_img&populate=kaisar_earn_workers.earning_img&populate=kaisar_explorers.button&populate=kaisar_explorers.gpu_locations.location.total_device_active&populate=kaisar_explorers.gpu_locations.location.icon&populate=kaisar_explorers.gpu_locations.image&populate=kaisar_explorers.cluster_img.image&populate=kaisar_explorers.score.image`
            );
            dispatch(slice.actions.getLandingPageSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAboutUsPage() {
    return async () => {
        try {
            const response = await axiosServices.get(
                `${apiUrl}/api/kaisar-abouts?filters[domain_services][type][$eq]=${domain}&populate=header.image&populate=features.features.icon&populate=mission.image&populate=mission.icon&populate=vision.image&populate=partners.image.image&populate=satisfactions_section.satisfactions.icon&populate=empower_map.map.image`
            );
            dispatch(slice.actions.getAboutUsPageSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getService() {
    return async () => {
        try {
            const response = await axiosServices.get(
                `${apiUrl}/api/service-details?filters[domain_service][type][$eq]=${domain}&populate=social&populate=contact&populate=logo&populate=logo_dark`
            );
            dispatch(slice.actions.getServiceSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getFAQs() {
    return async () => {
        try {
            const response = await axiosServices.get(
                `${apiUrl}/api/kaisar-faqs?filters[domain_services][type][$eq]=${domain}&populate=faq.icon&populate=faq.quesion_answer`
            );
            dispatch(slice.actions.getFAQsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
