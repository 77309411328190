import { useEffect } from 'react';

// store
import { dispatch } from 'store';

import { getService } from 'store/slices/cms';

// mui
import { Container, Grid, Link, Box, Avatar } from '@mui/material';
import { Facebook, LinkedIn, Telegram } from '@mui/icons-material';
// import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LogoSection from '../LogoSection';
import { SocialProps } from 'pages/landing/components/HeaderSection';

interface LinkProps {
    title: string;
    url: string;
    isNavigate: boolean;
}

const Footer = () => {
    const social: SocialProps[] = [
        {
            name: 'facebook',
            title: 'Facebook',
            link: 'https://www.facebook.com/xorcloud',
            isNavigate: false
        },
        {
            name: 'twitter',
            title: 'Twitter',
            link: ' https://x.com/xorcloud',
            isNavigate: false
        },
        {
            name: 'youtube',
            title: 'Linkedin',
            link: 'https://www.youtube.com/@x-or-cloud',
            isNavigate: false
        },
        {
            name: 'telegram',
            title: 'Telegram',
            link: 'https://t.me/xor-cloud',
            isNavigate: false
        }
    ];
    const link: LinkProps[] = [
        {
            title: 'About Us',
            url: 'https://docs.x-or.cloud/',
            isNavigate: false
        },
        {
            title: 'Terms & Privacy',
            url: ' https://docs.x-or.cloud/dieu-khoan-dich-vu',
            isNavigate: false
        },
        {
            title: 'SLAs',
            url: 'https://docs.x-or.cloud/dieu-khoan-dich-vu/cam-ket-chat-luong-dich-vu-sla',
            isNavigate: false
        },
        {
            title: 'Contact Us',
            url: 'mailto:sales@x-or.cloud',
            isNavigate: false
        }
    ];

    const handleLinkClick = ({ url, isNavigate }: { url: string; isNavigate: boolean }) => {
        if (!isNavigate) {
            window.open(url, '_blank');
        }
    };

    const handleIconClick = (url: string) => {
        window.open(url, '_blank');
    };

    useEffect(() => {
        dispatch(getService());
    }, []);

    // GET ICON BRAND

    return (
        <Container sx={{ padding: 1, mt: '50px', mb: '50px' }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={8} sx={{ marginTop: '20px' }}>
                    <Grid container spacing={3}>
                        {link.map((l, i) => {
                            return (
                                <Grid item xs={12} sm={12} md={3} lg={3} sx={{ textAlign: 'start' }} key={i}>
                                    <Link
                                        onClick={() => handleLinkClick({ url: l.url, isNavigate: l.isNavigate })}
                                        key={i}
                                        component={'button'}
                                        variant="h5"
                                        sx={{ textDecoration: 'none', underline: 'none' }}
                                    >
                                        {l.title}
                                    </Link>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    sx={{
                        padding: { xs: 'auto', md: 0 },
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <LogoSection />
                    <Box sx={{ display: 'flex', gap: { xs: 1, md: 3 } }}>
                        {social.map((s, i) => {
                            let icon;
                            switch (s.name) {
                                case 'twitter':
                                    icon = (
                                        <svg className="css-icon" viewBox="0 0 24 24" fill="none" width="20px" height="20px">
                                            <path
                                                d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                                                fill="#ffffff"
                                            />
                                        </svg>
                                    );
                                    // icon = <XIcon sx={{ color: '#fff' }} />;
                                    break;
                                case 'facebook':
                                    icon = <Facebook sx={{ color: '#fff' }} />;
                                    break;
                                case 'linkedin':
                                    icon = <LinkedIn sx={{ color: '#fff' }} />;
                                    break;
                                case 'telegram':
                                    icon = <Telegram sx={{ color: '#fff' }} />;
                                    break;
                                case 'youtube':
                                    icon = <YouTubeIcon sx={{ color: '#fff' }} />;
                                    break;
                                default:
                                    icon = null;
                            }

                            return icon ? (
                                <Avatar
                                    key={i}
                                    onClick={() => handleIconClick(s.link)}
                                    sx={{
                                        cursor: 'pointer',
                                        backgroundImage: 'linear-gradient(30deg, #0400ff, #4ce3f7)',
                                        '&:hover': {
                                            backgroundPosition: 'right center',
                                            backgroundSize: '200% auto',
                                            animation: 'pulse512 1.5s infinite'
                                        }
                                    }}
                                >
                                    {icon}
                                </Avatar>
                            ) : null;
                        })}
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Footer;
