import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';

// sample page routing
const LandingPage = Loadable(lazy(() => import('pages/landing/index')));
const AIPricePage = Loadable(lazy(() => import('pages/pricing/AIPrice')));
const CloudPricePage = Loadable(lazy(() => import('pages/pricing/CloudPrice')));
const StackPricePage = Loadable(lazy(() => import('pages/pricing/StackPrice')));
const DataPricePage = Loadable(lazy(() => import('pages/pricing/DataPrice')));
const ErrorPage = Loadable(lazy(() => import('pages/pages/maintenance/Error')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <LandingPage />
        },
        {
            path: '/pricing/x-or-ai',
            element: <AIPricePage />
        },
        {
            path: '/pricing/x-or-cloud',
            element: <CloudPricePage />
        },
        {
            path: '/pricing/x-or-stack',
            element: <StackPricePage />
        },
        {
            path: '/pricing/x-or-data',
            element: <DataPricePage />
        },
        {
            path: '*',
            element: <ErrorPage />
        }
    ]
};

export default MainRoutes;
