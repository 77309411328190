import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
// import { useEffect } from 'react';
// import { RootState, dispatch, useSelector } from 'store';
// import { getService } from 'store/slices/cms';
// import { apiUrl } from 'utils/config';
// import { useTheme } from '@mui/system';
import logo from 'assets/images/logo.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    // const theme = useTheme();
    // const service = useSelector((state: RootState) => state.cms.service);

    // useEffect(() => {
    //     dispatch(getService());
    // }, []);
    return (
        <Link component={RouterLink} to={'/'} aria-label="x-or">
            <img src={logo} alt="logo" style={{ height: '80px', objectFit: 'cover' }} />
            {/* <img
                src={
                    theme.palette.mode === 'dark'
                        ? `${apiUrl}${service?.data?.[0]?.attributes?.logo_dark?.data?.attributes?.url}`
                        : `${apiUrl}${service?.data?.[0]?.attributes?.logo?.data?.attributes?.url}`
                }
                alt="logo"
                style={{ height: '40px', objectFit: 'cover' }}
            /> */}
        </Link>
    );
};

export default LogoSection;
